import { type RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Spinner,
  Stack,
  TextLink,
} from '@spaceship-fspl/components';
import {
  MoneyDepositSource,
  MoneyPaymentSource,
  useCreateMoneyDeposit,
} from '@spaceship-fspl/graphql';
import {
  cleanNumber,
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatDate,
} from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import { GENERIC_WARNING_MESSAGE } from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { TestId } from 'helpers/test-ids';
import { Routes } from 'pages/routes';
import React from 'react';

import { MONEY_TRANSACTIONS_CHUNK_SIZE } from './dashboard';

interface MoneyDepositConfirmProps {
  location: {
    state: {
      audAmount: string;
      etaDate: string;
    };
  };
}

export const MoneyDepositConfirm: React.FC<
  React.PropsWithChildren<RouteComponentProps<MoneyDepositConfirmProps>>
> = ({ location }) => {
  const navigate = useNavigate();

  const audAmount = location?.state?.audAmount;
  const etaDate = location?.state?.etaDate;

  const notifications = useNotifications();
  const [createMoneyDeposit, createMoneyDepositMeta] = useCreateMoneyDeposit();

  const submissionDisabled =
    createMoneyDepositMeta.loading || audAmount == null;

  const onConfirm = async (): Promise<void> => {
    if (!submissionDisabled) {
      const amount = cleanNumber(audAmount);

      createMoneyDeposit({
        variables: {
          input: {
            audAmount: amount,
            paymentSource: MoneyPaymentSource.DIRECT_ENTRY,
            source: MoneyDepositSource.DIRECT_ENTRY,
          },
          typeFilters: undefined,
          first: MONEY_TRANSACTIONS_CHUNK_SIZE,
        },
        onCompleted: () => {
          navigate(`${Routes.MONEY_DEPOSIT_SUCCESS}`, {
            state: {
              audAmount: amount,
              etaDate,
            },
          });
        },
        onError: (error) => {
          addRumError({ error });
          notifications.popToast({
            level: 'warning',
            message: GENERIC_WARNING_MESSAGE,
          });
        },
      });
    }
  };

  return (
    <PageContainer data-testid={TestId.MONEY_DEPOSIT_CONFIRM_PAGE}>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
          <Heading variant={3} align="center">
            Confirm
          </Heading>

          <Box
            backgroundColor="neutral.000"
            padding="lg"
            borderRadius="sm"
            boxShadow="sm"
            marginTop="md"
          >
            <Stack spaceY="sm">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabeledField label="Amount" align="left" size="sm">
                  {audAmount ?? '—'}
                </LabeledField>

                <TextLink
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(Routes.MONEY_DEPOSIT);
                  }}
                >
                  Edit
                </TextLink>
              </Box>

              <Divider color="neutral.030" />

              <LabeledField label="Investment complete" align="left" size="sm">
                {etaDate
                  ? `ETA ${formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)}`
                  : '—'}
              </LabeledField>
            </Stack>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="xl"
          >
            <Stack spaceY="xs" alignX="center">
              <Button
                data-testid={TestId.MONEY_DEPOSIT_CONFIRM_PAGE_CONFIRM_BUTTON}
                variant="primary"
                size="lg"
                isDisabled={submissionDisabled}
                isLoading={createMoneyDepositMeta.loading}
                trackingProperties={{
                  name: 'money_deposit_onfirm_submit',
                }}
                onClick={onConfirm}
              >
                {createMoneyDepositMeta.loading ? (
                  <Spinner size="sm" />
                ) : (
                  'Confirm'
                )}
              </Button>

              <Box marginTop="sm">
                <TextLink
                  data-testid={TestId.MONEY_DEPOSIT_CONFIRM_PAGE_CANCEL}
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(Routes.MONEY);
                  }}
                >
                  Cancel
                </TextLink>
              </Box>
            </Stack>
          </Box>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};

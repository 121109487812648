import { gql, useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Spinner,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { MoneyTransactionLimits_MoneyInstanceFragment } from '@spaceship-fspl/graphql';
import { WebAppMoneyDepositPage } from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDepositPage';
import { useIsStatusVerified } from '@spaceship-fspl/green-id';
import {
  addDays,
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatCurrency,
  formatDate,
  maskBankAccountNumber,
  sydneyDate,
} from '@spaceship-fspl/helpers';
import { FeatherClockIcon, FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import { GreenIdRuleSet } from '@spaceship-fspl/types/externalapi';
import { Button } from 'components/button';
import { ControllerInput } from 'components/controller-input';
import { Error, InlineContactSupportMessage } from 'components/layouts/error';
import {
  CenterPageContainer,
  PageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageFormGoBackButton,
  PageHeading,
} from 'components/layouts/page';
import { PendingVerification } from 'components/layouts/pending-verification';
import { useIntercom } from 'contexts/intercom';
import { FeatureFlagKeys, useFeatureFlag } from 'helpers/dynamic-config';
import { cleanNumber, toAussieDate } from 'helpers/format';
import { TestId } from 'helpers/test-ids';
import { commonValidationRules } from 'helpers/validation';
import { Routes } from 'pages/routes';
import React from 'react';
import { useForm } from 'react-hook-form';

const DIRECT_DEBIT_DEPOSIT_MIN_AUD_AMOUNT_FALLBACK = '0.01';
const DIRECT_DEBIT_DEPOSIT_MAX_AUD_AMOUNT_FALLBACK = '50000';
const DIRECT_DEBIT_DEPOSIT_AVAILABLE_FALLBACK = false;
const DIRECT_DEBIT_DEPOSIT_NOT_AVAILABLE_MESSAGE_FALLBACK = `We were not able to verify your direct debit information. If the issue persists, please contact support.`;

export const MoneyDeposit: React.FC = () => {
  const isMoneyDayOneEnabled = useFeatureFlag(
    FeatureFlagKeys.MONEY_DAY_ONE_ENABLED,
  );
  const intercom = useIntercom();

  const { control, handleSubmit, watch, formState } = useForm<{
    audAmount: string;
  }>({
    defaultValues: {
      audAmount: '',
    },
    mode: 'onChange',
  });

  const resp = useQuery<WebAppMoneyDepositPage>(
    gql`
      query WebAppMoneyDepositPage($isMoneyDayOneEnabled: Boolean! = false) {
        contact {
          id
          account {
            id
            activeBankAccount {
              id
              accountNumber
            }
            ... on Account @include(if: $isMoneyDayOneEnabled) {
              moneyAvailableAudBalance
              moneyInstance {
                id
                ... on MoneyInstance {
                  ...MoneyTransactionLimits_MoneyInstanceFragment
                }
              }
            }
          }
        }
      }
      ${MoneyTransactionLimits_MoneyInstanceFragment}
    `,
    {
      variables: { isMoneyDayOneEnabled },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isLoading = resp.loading;
  const account = resp.data?.contact?.account;
  const accountNumber = account?.activeBankAccount?.accountNumber;

  const transactionLimits = account?.moneyInstance?.transactionLimits;
  const directDebitDepositAvailable =
    transactionLimits?.directDebitDepositAvailable ??
    DIRECT_DEBIT_DEPOSIT_AVAILABLE_FALLBACK;
  const directDebitDepositMinAudAmount =
    transactionLimits?.directDebitDepositMinAudAmount ??
    DIRECT_DEBIT_DEPOSIT_MIN_AUD_AMOUNT_FALLBACK;
  const directDebitDepositMaxAudAmount =
    transactionLimits?.directDebitDepositMaxAudAmount ??
    DIRECT_DEBIT_DEPOSIT_MAX_AUD_AMOUNT_FALLBACK;
  const directDebitDepositNotAvailableMessage =
    transactionLimits?.directDebitDepositNotAvailableMessage ??
    DIRECT_DEBIT_DEPOSIT_NOT_AVAILABLE_MESSAGE_FALLBACK;

  const audAmount = watch('audAmount');
  const availableAudBalance = Number(account?.moneyAvailableAudBalance ?? 0);

  const maxAmount = Number(directDebitDepositMaxAudAmount ?? 0);
  const maxBalanceReached = availableAudBalance > maxAmount;

  const formDisabled = maxBalanceReached;

  // @rv: this date needs to be confirmed with product/ops + only business days
  const estimatedExecutionDate = toAussieDate(addDays(sydneyDate(), 2));
  const formattedEstimatedExecutionDate = formatDate(
    estimatedExecutionDate,
    DATE_FORMAT_TRANSACTIONS_LONG,
  );

  const novaVerified = useIsStatusVerified(GreenIdRuleSet.Enum.NOVA);
  const voyagerVerified = useIsStatusVerified(
    GreenIdRuleSet.Enum.VOYAGER_ONBOARDING,
  );
  const isVerifiedUser = novaVerified || voyagerVerified;

  const submit = handleSubmit(async ({ audAmount }) => {
    navigate(Routes.MONEY_DEPOSIT_CONFIRM, {
      state: {
        audAmount,
        etaDate: formattedEstimatedExecutionDate,
      },
    });
  });

  if (isLoading) {
    return (
      <CenterPageContainer>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      </CenterPageContainer>
    );
  }

  if (resp.error && !resp.data && !isLoading) {
    return (
      <CenterPageContainer>
        <Error
          iconColor="indigo.070"
          title="We’ve run into a problem."
          buttonText="Try again"
          subtitle={<InlineContactSupportMessage />}
          secondaryButton={
            <TextLink
              hideUnderline
              color="indigo.070"
              onClick={() => {
                navigate(Routes.VOYAGER_DASHBOARD);
              }}
            >
              Go back
            </TextLink>
          }
          onContinue={{
            trackingProperties: {
              name: 'money_deposit_error_try_again',
            },
            onClick: () => {
              resp.refetch();
            },
          }}
        />
      </CenterPageContainer>
    );
  }

  if (!isVerifiedUser) {
    return (
      <CenterPageContainer>
        <PendingVerification
          variant="investment"
          onBack={{
            trackingProperties: {
              name: 'money_deposit_verify_go_back',
            },
            onClick: () => navigate(Routes.VOYAGER_DASHBOARD),
          }}
        />
      </CenterPageContainer>
    );
  }

  return (
    <PageContainer>
      <Stack spaceY="xxl" data-testid={TestId.MONEY_DEPOSIT_PAGE}>
        <form onSubmit={submit}>
          <Columns alignX="center">
            <Columns.Column
              width={{
                xs: 1,
                lg: 6 / 12,
                xl: 4 / 12,
              }}
            >
              <Stack spaceY="md">
                <Stack spaceY="lg">
                  <PageHeading
                    title={'How much money would you like to add?'}
                  />
                </Stack>

                {!directDebitDepositAvailable ? (
                  <Stack spaceY="md" alignX="center">
                    <Box
                      backgroundColor="neutral.000"
                      borderRadius="xs"
                      padding="sm"
                      display="flex"
                    >
                      <FeatherInfoIcon color="indigo.070" />
                      <Box flex={1} marginLeft="xs">
                        <Text variant={3}>
                          {directDebitDepositNotAvailableMessage}
                        </Text>
                      </Box>
                    </Box>

                    <Button
                      variant="primary"
                      size="lg"
                      trackingProperties={{
                        name: 'money_deposit_direct_debit_not_available_go_back',
                      }}
                      onClick={() => navigate(-1)}
                      data-testid={TestId.MONEY_DEPOSIT_PAGE_GO_BACK_BUTTON}
                    >
                      Go back
                    </Button>
                    <Button
                      variant="secondary"
                      size="lg"
                      trackingProperties={{
                        name: 'money_deposit_direct_debit_not_available_contact_support',
                      }}
                      onClick={() => intercom.pop()}
                      data-testid={
                        TestId.MONEY_DEPOSIT_PAGE_CONTACT_SUPPORT_BUTTON
                      }
                    >
                      Contact support
                    </Button>
                  </Stack>
                ) : (
                  <>
                    <Stack spaceY="xs">
                      <ControllerInput
                        name="audAmount"
                        control={control}
                        width="100%"
                        placeholder="Amount"
                        type="text"
                        format="currency"
                        disabled={formDisabled}
                        rules={{
                          required: 'Amount is required',
                          validate: {
                            atLeast: (value?: string): boolean | string =>
                              value
                                ? commonValidationRules.atLeast.validate(
                                    directDebitDepositMinAudAmount,
                                    value,
                                  )
                                : false,
                            atMost: (value?: string): boolean | string =>
                              value
                                ? commonValidationRules.atMost.validate(
                                    directDebitDepositMaxAudAmount,
                                    value,
                                  )
                                : false,
                            totalNotExceeding: (value): boolean | string => {
                              const amount = Number(cleanNumber(value));
                              const combinedAmount =
                                Number(availableAudBalance ?? 0) +
                                Number(amount);

                              if (combinedAmount > maxAmount) {
                                return `Total balance cannot exceed ${formatCurrency(
                                  maxAmount,
                                )}.`;
                              }

                              return true;
                            },
                          },
                        }}
                      />
                      {maxBalanceReached && (
                        <Columns spaceX={'xxxs'}>
                          <Columns.Column width={'min'}>
                            <FeatherInfoIcon
                              color={'pink.030'}
                              size="sm"
                              strokeWidth={1.5}
                            />
                          </Columns.Column>
                          <Columns.Column width={'min'}>
                            <Text variant={3} color="pink.030" isBold={true}>
                              {`You've reached the maximum limit.`}
                            </Text>
                          </Columns.Column>
                        </Columns>
                      )}
                    </Stack>

                    <Box marginTop="sm">
                      <Stack spaceY="sm">
                        <Columns>
                          <Columns.Column width={1 / 2}>
                            <Stack spaceY="xxxs">
                              <Text
                                variant={4}
                                color="neutral.080"
                                isBold={true}
                              >
                                Now
                              </Text>
                              <Text
                                variant={2}
                                isBold={true}
                                data-testid={
                                  TestId.MONEY_DEPOSIT_PAGE_NOW_AMOUNT
                                }
                              >
                                {!!isLoading && !availableAudBalance ? (
                                  <Spinner />
                                ) : (
                                  formatCurrency(availableAudBalance)
                                )}
                              </Text>
                            </Stack>
                          </Columns.Column>
                          <Columns.Column width={1 / 2}>
                            <Stack spaceY="xxxs">
                              <Text
                                variant={4}
                                color="neutral.080"
                                isBold={true}
                              >
                                After
                              </Text>
                              <Text
                                variant={2}
                                isBold={true}
                                color={
                                  formState.isValid ? 'pink.030' : 'neutral.100'
                                }
                                data-testid={
                                  TestId.MONEY_DEPOSIT_PAGE_AFTER_AMOUNT
                                }
                              >
                                {formState.isValid
                                  ? formatCurrency(
                                      Number(availableAudBalance ?? 0) +
                                        Number(cleanNumber(audAmount)),
                                    )
                                  : '—'}
                              </Text>
                            </Stack>
                          </Columns.Column>
                        </Columns>

                        <Divider color="neutral.030" />

                        <Stack spaceY="xxxs">
                          <Text variant={4} color="neutral.080" isBold={true}>
                            Source
                          </Text>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                          >
                            <Text variant={2} isBold={true}>
                              Linked bank account
                            </Text>
                          </Box>
                          <Text variant={3} color="neutral.080">
                            We’ll debit the money from your linked bank account
                            {accountNumber &&
                              ` ending in ${maskBankAccountNumber(accountNumber)}`}
                            .
                          </Text>
                        </Stack>
                      </Stack>
                    </Box>

                    {!maxBalanceReached && formattedEstimatedExecutionDate && (
                      <Box
                        backgroundColor="neutral.030"
                        borderRadius="xs"
                        padding="md"
                      >
                        <Stack spaceY="xxs" alignX="center">
                          <Box color="indigo.070">
                            <FeatherClockIcon size="md" />
                          </Box>
                          <Text variant={2} align="center">
                            The ETA of your deposit is{' '}
                            <Text variant={2} isBold={true} component="span">
                              {formattedEstimatedExecutionDate}
                            </Text>
                          </Text>
                        </Stack>
                      </Box>
                    )}

                    <PageFormButtonContainer>
                      {maxBalanceReached ? (
                        <PageFormGoBackButton
                          trackingProperties={{
                            name: 'money_deposit_reached_maximum_balance_go_back',
                          }}
                          onClick={() => {
                            navigate(-1);
                          }}
                          data-testid={TestId.MONEY_DEPOSIT_PAGE_GO_BACK_BUTTON}
                        />
                      ) : (
                        <>
                          <PageFormContinueButton
                            trackingProperties={{
                              name: 'money_deposit_confirm',
                            }}
                            data-testid={
                              TestId.MONEY_DEPOSIT_PAGE_CONFIRM_BUTTON
                            }
                          >
                            Confirm
                          </PageFormContinueButton>

                          <Box marginTop="sm">
                            <TextLink
                              data-testid={
                                TestId.MONEY_DEPOSIT_PAGE_CANCEL_BUTTON
                              }
                              hideUnderline
                              color="indigo.070"
                              onClick={() => {
                                navigate(Routes.MONEY);
                              }}
                            >
                              Cancel
                            </TextLink>
                          </Box>
                        </>
                      )}
                    </PageFormButtonContainer>
                  </>
                )}
              </Stack>
            </Columns.Column>
          </Columns>
        </form>
      </Stack>
    </PageContainer>
  );
};

import { gql } from '@apollo/client';
import {
  Box,
  Columns,
  Divider,
  InfoPanel,
  Inline,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  bankAccountScalars,
  MoneyPaymentSource,
  MoneyTransactionStatus,
  MoneyTransactionType,
  ScheduleFrequency,
  scheduleScalars,
  unitExchangeScalars,
  unitPriceScalars,
  VoyagerTransaction_ApplicationFragment,
  VoyagerTransaction_BoostFragment,
  VoyagerTransaction_RedemptionFragment,
  VoyagerTransaction_SaverProductInstanceFragment,
  VoyagerTransaction_UnitExchangeFragment,
} from '@spaceship-fspl/graphql';
import {
  MoneyTransactionDetails_MoneyTransactionDetailsFragment as MoneyTransactionDetails_MoneyTransactionDetailsFragmentType,
  type MoneyTransactionDetails_MoneyTransactionDetailsFragment_productTransaction,
} from '@spaceship-fspl/graphql/src/__generated__/MoneyTransactionDetails_MoneyTransactionDetailsFragment';
import {
  capitalizeWords,
  formatCurrency,
  formatDate,
  maskBankAccountNumber,
} from '@spaceship-fspl/helpers';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  borderWidthTop,
  Color,
  getColor,
  getSpace,
  match,
  paddingBottom,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { LabeledField } from 'components/labeled-field';
import {
  DATE_FORMAT_TRANSACTIONS,
  ProductTransactionItem,
} from 'components/transactions';
import { format } from 'date-fns';
import { voyagerPortfolios } from 'helpers/portfolios';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled, { css } from 'styled-components';

import { MoneyTransactionStatusIndicator } from './money-transaction-status-indicator';

export const MoneyTransactionDetails_MoneyTransactionDetailsFragment = gql`
  fragment MoneyTransactionDetails_MoneyTransactionDetailsFragment on MoneyTransaction {
    id
    moneyReference
    audAmount
    pendingAudAmount
    status
    createdAt
    lastUpdatedAt
    statusMessage
    type
    productTransaction {
      id
      ... on Application {
        id
        ...VoyagerTransaction_ApplicationFragment
      }
      ... on Boost {
        id
        ...VoyagerTransaction_BoostFragment
      }
      ... on Redemption {
        id
        ...VoyagerTransaction_RedemptionFragment
      }
      ... on NovaBuyTransaction {
        id
        asset {
          id
          symbol
        }
      }
      ... on NovaSellTransaction {
        id
        asset {
          id
          symbol
        }
      }
      ... on NovaAccountFeeTransaction {
        id
      }
      ... on NovaDividendTransaction {
        id
        asset {
          id
          symbol
        }
      }
      ... on NovaRegulatoryFeeTransaction {
        id
        type
      }
      ... on NovaMergerAcquisitionTransaction {
        id
        asset {
          id
          symbol
        }
      }
    }
    paymentSourceDetails {
      paymentSource
      bankAccount {
        id
        accountName
        accountNumber
        bsb
      }
    }
  }
  ${bankAccountScalars}
  ${scheduleScalars}
  ${unitExchangeScalars}
  ${unitPriceScalars}
  ${VoyagerTransaction_UnitExchangeFragment}
  ${VoyagerTransaction_SaverProductInstanceFragment}
  ${VoyagerTransaction_ApplicationFragment}
  ${VoyagerTransaction_RedemptionFragment}
  ${VoyagerTransaction_BoostFragment}
`;

interface MoneyTransactionItemProps {
  moneyTransaction: MoneyTransactionDetails_MoneyTransactionDetailsFragmentType;
  isExpandable?: boolean;
}

export const MoneyTransactionItem: React.FC<
  React.PropsWithChildren<MoneyTransactionItemProps>
> = ({ isExpandable, moneyTransaction }) => {
  if (isExpandable) {
    return (
      <StyledDetails
        data-testid={TestId.MONEY_TRANSACTION_DETAILS}
        key={moneyTransaction.id}
      >
        <StyledSummary data-testid={TestId.MONEY_TRANSACTION_SUMMARY}>
          <TransactionDetails moneyTransaction={moneyTransaction} />
        </StyledSummary>
        <MoneyTransactionExpansionPanel moneyTransaction={moneyTransaction} />
      </StyledDetails>
    );
  }

  return (
    <StyledContentOnlyContainer
      data-testid={TestId.MONEY_TRANSACTION_SUMMARY}
      key={moneyTransaction.id}
    >
      <TransactionDetails moneyTransaction={moneyTransaction} />
    </StyledContentOnlyContainer>
  );
};

export const MoneyTransactionExpansionPanel: React.FC<
  TransactionDetailsProps
> = ({ moneyTransaction }) => {
  switch (moneyTransaction.type) {
    case MoneyTransactionType.MONEY_IN:
    case MoneyTransactionType.MONEY_OUT: {
      const referenceNumber = moneyTransaction?.moneyReference ?? '—';
      const { accountName = '—', bsb = '—' } =
        moneyTransaction?.paymentSourceDetails?.bankAccount ?? {};

      const accountNumber = moneyTransaction?.paymentSourceDetails?.bankAccount
        ?.accountNumber
        ? maskBankAccountNumber(
            moneyTransaction.paymentSourceDetails.bankAccount.accountNumber,
          )
        : '—';

      const transactionTime = moneyTransaction?.createdAt
        ? `${formatDate(moneyTransaction.createdAt, 'dd MMM yyyy h:mm a')}`
        : '—';

      return (
        <Stack spaceY={{ xs: 'sm', md: 'md' }}>
          <Divider color="neutral.050" />

          <StyledContentBox>
            <Stack spaceY="md">
              <Columns spaceX="lg" spaceY="sm">
                <Columns.Column width="min">
                  <LabeledField size="sm" label="Initiated">
                    {transactionTime}
                  </LabeledField>
                </Columns.Column>

                <Columns.Column width="min">
                  <LabeledField size="sm" label="Reference number">
                    {referenceNumber}
                  </LabeledField>
                </Columns.Column>
              </Columns>

              <Columns spaceX="lg" spaceY="sm">
                <Columns.Column width="min">
                  <LabeledField size="sm" label="Linked bank account">
                    {accountName}
                  </LabeledField>
                </Columns.Column>

                <Columns.Column width="min">
                  <Inline spaceX="lg">
                    <LabeledField size="sm" label="BSB">
                      {bsb}
                    </LabeledField>
                    <LabeledField size="sm" label="Account number">
                      {accountNumber}
                    </LabeledField>
                  </Inline>
                </Columns.Column>
              </Columns>
            </Stack>
          </StyledContentBox>
        </Stack>
      );
    }
    default: {
      const productTransaction = moneyTransaction.productTransaction;
      return <ProductTransactionItem tx={productTransaction} />;
    }
  }
};

interface TransactionDetailsProps {
  moneyTransaction: MoneyTransactionDetails_MoneyTransactionDetailsFragmentType;
}

const formatProductTransactionTitle = (
  productTransaction?: MoneyTransactionDetails_MoneyTransactionDetailsFragment_productTransaction | null,
): string | undefined => {
  switch (productTransaction?.__typename) {
    case 'NovaAccountFeeTransaction': {
      return 'US Investing monthly fee';
    }

    case 'NovaRegulatoryFeeTransaction': {
      const feeType = productTransaction?.type;

      return `US Investing ${feeType} (regulatory) fee`;
    }

    case 'NovaSellTransaction': {
      const symbol = productTransaction?.asset?.symbol;

      return `Sell - ${symbol}`;
    }

    case 'NovaBuyTransaction': {
      const symbol = productTransaction?.asset?.symbol;

      return `Buy - ${symbol}`;
    }

    case 'NovaDividendTransaction': {
      const symbol = productTransaction?.asset?.symbol;

      return `Dividend (Cash) - ${symbol}`;
    }
    case 'Application': {
      const frequency = productTransaction?.schedule?.frequency;
      const portfolio =
        voyagerPortfolios[productTransaction?.saverProductInstance?.portfolio]
          ?.name;

      return `${frequency === ScheduleFrequency.ONE_TIME ? 'One-off investment' : `Investment plan (${frequency?.toLocaleLowerCase()})`} - ${portfolio}`;
    }
    case 'Redemption': {
      const fullRedemption = productTransaction?.fullRedemption;
      const portfolio =
        voyagerPortfolios[productTransaction?.saverProductInstance?.portfolio]
          ?.name;

      return `${fullRedemption ? 'Full withdrawal' : 'Withdrawal'} - ${portfolio}`;
    }
    case 'Boost': {
      const boost = productTransaction?.itemGroup?.recipe?.name;
      return `Boost - ${boost}`;
    }

    case 'NovaMergerAcquisitionTransaction': {
      const symbol = productTransaction?.asset?.symbol;

      return `Merger/acquisition - ${symbol}`;
    }

    default:
      return undefined;
  }
};

/**
 * transaction details for the money transaction
 */
const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  moneyTransaction,
}) => {
  const productTransaction = moneyTransaction.productTransaction;
  let transactionLabel: string | undefined = undefined;
  let amountPrefix = '';

  switch (moneyTransaction.type) {
    case MoneyTransactionType.MONEY_IN: {
      transactionLabel = 'Money in';
      amountPrefix = '+';
      break;
    }
    case MoneyTransactionType.MONEY_OUT: {
      transactionLabel = 'Money out';
      break;
    }
    case MoneyTransactionType.REG_FEE: {
      transactionLabel = 'SEC/REG (regulatory) fee';
      break;
    }
    case MoneyTransactionType.TAF_FEE: {
      transactionLabel = 'TAF (regulatory) fee';
      break;
    }
    case MoneyTransactionType.FEE: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      break;
    }
    case MoneyTransactionType.PURCHASE: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      break;
    }
    case MoneyTransactionType.SALE: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      amountPrefix = '+';
      break;
    }
    case MoneyTransactionType.DIVIDEND: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      amountPrefix = '+';
      break;
    }
    case MoneyTransactionType.DISTRIBUTION: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      break;
    }
    case MoneyTransactionType.BOOST: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      break;
    }
    case MoneyTransactionType.MERGER_ACQUISITION: {
      transactionLabel = formatProductTransactionTitle(productTransaction);
      amountPrefix = '+';
      break;
    }
  }

  const handleAudAmountColour = (): Color => {
    switch (status) {
      case MoneyTransactionStatus.FAILED:
      case MoneyTransactionStatus.CANCELLED: {
        return 'neutral.080';
      }
      default: {
        if (amountPrefix === '+') {
          return 'green.100';
        }
        return 'neutral.100';
      }
    }
  };

  const handleMapDepositSource = (
    source?: MoneyPaymentSource | null,
  ): string => {
    switch (source) {
      case MoneyPaymentSource.DIRECT_ENTRY: {
        return '- Direct entry';
      }
      case MoneyPaymentSource.PAYTO: {
        return '- Pay to';
      }
      default: {
        return '';
      }
    }
  };

  const parsedAudAmount = formatCurrency(
    Math.abs(Number(moneyTransaction.audAmount)),
  );
  const parsedPendingAudAmount = moneyTransaction.pendingAudAmount
    ? formatCurrency(Math.abs(Number(moneyTransaction.pendingAudAmount)))
    : null;

  return (
    <Box>
      <Columns>
        <Columns.Column width={9 / 12}>
          <Columns>
            <Columns.Column width={{ xs: 1, lg: 3 / 12 }}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom={{ xs: 'xs', md: 'none' }}
              >
                <Text
                  whiteSpace="nowrap"
                  variant={4}
                  isBold={true}
                  color="neutral.080"
                >
                  {moneyTransaction.createdAt
                    ? format(
                        new Date(moneyTransaction.createdAt),
                        DATE_FORMAT_TRANSACTIONS,
                      )
                    : 'Pending'}
                </Text>
              </Box>
            </Columns.Column>
            <Columns.Column width={{ xs: 1, lg: 7 / 12 }}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom={{ xs: 'xs', md: 'none' }}
                marginX={{ lg: 'sm' }}
              >
                <Text variant={3} isBold={true} align="left">
                  {transactionLabel}{' '}
                  {moneyTransaction.statusMessage
                    ? `(${moneyTransaction.statusMessage})`
                    : null}{' '}
                  {handleMapDepositSource(
                    moneyTransaction.paymentSourceDetails?.paymentSource,
                  )}
                </Text>
              </Box>
            </Columns.Column>
            <Columns.Column width={{ xs: 1, lg: 2 / 12 }}>
              <Box
                data-testid={TestId.MONEY_TRANSACTION_STATUS}
                display="flex"
                alignItems="center"
              >
                <MoneyTransactionStatusIndicator
                  status={moneyTransaction.status}
                />
                <Text variant={4} isBold={true} color={'neutral.080'}>
                  {capitalizeWords(moneyTransaction.status)}
                </Text>
              </Box>
            </Columns.Column>
          </Columns>
        </Columns.Column>
        <Columns.Column width={3 / 12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={'flex-start'}
          >
            <Text
              variant={3}
              isBold={true}
              align="right"
              color={handleAudAmountColour()}
            >
              {`${amountPrefix}${formatCurrency(moneyTransaction.audAmount)}`}
            </Text>
          </Box>
        </Columns.Column>
      </Columns>
      <>
        {parsedPendingAudAmount &&
        parsedAudAmount &&
        moneyTransaction.type === MoneyTransactionType.PURCHASE ? (
          <Box marginTop="md">
            <InfoPanel
              color="black.100"
              icon={FeatherInfoIcon}
              backgroundColor="indigo.010"
              iconColor="black.100"
              iconSize="sm"
              boxShadow="none"
              textVariant={4}
              textColor="black.100"
              leftBorderColor="indigo.010"
            >
              {`Your order completed for ${parsedAudAmount} instead of ${parsedPendingAudAmount}. We've returned the difference.`}
            </InfoPanel>
          </Box>
        ) : null}
      </>
    </Box>
  );
};
const dividerStyle = css`
  :not(:first-child) {
    ${borderWidthTop('sm')};
    border-top-style: solid;
    border-top-color: ${getColor('neutral.030')};
  }
`;

const StyledDetails = styled.details<{
  isExpandable?: boolean;
}>`
  ${dividerStyle}
  cursor: ${(props) => (props.isExpandable ? 'pointer' : 'unset')};
  position: relative;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    width: ${getSpace('xxxs')};
    background-color: transparent;
  }

  &[open] {
    ${backgroundColor('neutral.015')};

    ::before {
      ${backgroundColor('indigo.070')};
    }
  }

  [open] + &[open] {
    border-top-color: ${getColor('neutral.070')};
  }
`;

const StyledSummary = styled.summary<{
  isInlineComponent?: boolean;
}>`
  ${({ isInlineComponent }) => css`
    ${isInlineComponent
      ? css``
      : css`
          ${paddingY('md')}
          ${StyledDetails}[open] & {
            ${paddingBottom({ xs: 'sm', md: 'md' })}
          }
        `}

    ${transition}
    ${paddingX({ xs: 'md', md: 'lg' })}
    cursor: pointer;
    list-style: none;
    outline: none;

    :hover {
      ${backgroundColor('neutral.015')}
    }

    ::-webkit-details-marker,
    ::marker {
      display: none;
    }
  `}
`;

const DATE_MIN_WIDTH = 85;
const StyledContentBox = styled(Box).attrs({
  paddingX: { xs: 'md', md: 'lg' },
  paddingBottom: 'md',
})`
  ${match('md')`
    margin-left: calc(${DATE_MIN_WIDTH}px + ${getSpace('xxs')});
  `};
`;

const StyledContentOnlyContainer = styled.div`
  ${dividerStyle}
  ${paddingY('sm')}
  ${paddingX({ xs: 'md', md: 'lg' })}
`;

import { Box, Card, Text } from '@spaceship-fspl/components';
import {
  FeatherInfoIcon,
  FeatherXIcon,
  IconComponent,
} from '@spaceship-fspl/icons-web';
import {
  Color,
  getColor,
  getSpace,
  IconSize,
  shadows,
  Space,
  TextVariant,
} from '@spaceship-fspl/styles';
import React, {
  isValidElement,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

const InfoPanelWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: ${getSpace('sm')};
  overflow: hidden;
  text-align: left;
`;

const InfoPanelFill = styled.div<{
  borderLeftColor: Color;
}>`
  min-width: 11px;
  background-color: ${({ borderLeftColor }) => getColor(borderLeftColor)};
`;

type Props = {
  asText?: boolean;
  color: Color;
  icon: IconComponent;
  backgroundColor?: Color;
  isBold?: boolean;
  iconColor?: Color;
  iconSize?: IconSize;
  textColor?: Color;
  textVariant?: TextVariant;
  leftBorderColor?: Color;
  boxShadow?: keyof typeof shadows;
  testId?: string;
  marginBottom?: Space;
  hidePanelFill?: boolean;
};

export const InfoPanel: React.FC<PropsWithChildren<Props>> = ({
  backgroundColor,
  boxShadow,
  children,
  color,
  icon,
  iconColor,
  iconSize = 'md',
  isBold = false,
  leftBorderColor,
  marginBottom = 'xs',
  textColor,
  textVariant = 2,
  hidePanelFill = false,
  testId,
}) => {
  const Icon = icon;

  const resolvedBackgroundColor = backgroundColor
    ? backgroundColor
    : 'neutral.000';

  return (
    <InfoPanelWrapper
      marginBottom={marginBottom}
      boxShadow={boxShadow ?? 'md'}
      backgroundColor={resolvedBackgroundColor}
      data-testid={testId}
    >
      {!hidePanelFill ? (
        <InfoPanelFill
          data-testid="info-panel-fill"
          borderLeftColor={leftBorderColor ?? color}
        />
      ) : null}

      <Box
        display="flex"
        alignItems="center"
        paddingLeft={{ xs: 'none', sm: 'xs' }}
      >
        <Icon color={iconColor ?? color} size={iconSize} />
      </Box>
      <Box alignItems="center" display="flex" paddingX="xs" paddingY="sm">
        {isValidElement(children) ? (
          children
        ) : (
          <Text
            variant={textVariant}
            color={textColor ?? 'neutral.085'}
            isBold={isBold}
          >
            {children}
          </Text>
        )}
      </Box>
    </InfoPanelWrapper>
  );
};

const DismissibleInfoPanel = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: ${getSpace('sm')};
  overflow: hidden;
  margin-bottom: ${getSpace('sm')};
  text-align: left;
`;

const DismissibleButtonBox = styled(Box)`
  cursor: pointer;
`;

export const DisimissibleInfoPanel: React.FC<
  PropsWithChildren<{
    dismissible: boolean;
    dismissHandler: () => void;
    dismissalPending: boolean;
    dismissed?: boolean;
    color: Color;
    message?: string;
    backgroundColor?: Color;
    icon?: IconComponent;
  }>
> = ({
  children,
  dismissible,
  dismissalPending = false,
  dismissHandler,
  message,
  dismissed,
  icon: Icon,
  backgroundColor = 'neutral.000',
  color,
}) => {
  const [panelDismissed, setPanelDismissed] = useState<boolean>(true);

  useEffect(() => {
    setPanelDismissed(!!dismissed);
  }, [dismissed]);

  if (panelDismissed || (!children && !message)) {
    return null;
  }

  const handleDismissal: (handler: () => void, pending: boolean) => void = (
    handler,
    pending,
  ) => {
    if (!pending) {
      handler();
    }
  };

  return (
    <DismissibleInfoPanel boxShadow={'md'} backgroundColor={backgroundColor}>
      <InfoPanelFill borderLeftColor={color} />
      <Box
        alignItems="center"
        display="flex"
        paddingLeft={{ xs: 'none', sm: 'xs' }}
      >
        {Icon ? (
          <Icon color={color} />
        ) : (
          <FeatherInfoIcon color={color} size="md" />
        )}
      </Box>
      <Box alignItems="center" display="flex" paddingX="xs" paddingY="sm">
        {children ? (
          children
        ) : (
          <Text variant={3} color={'neutral.080'}>
            {message}
          </Text>
        )}
      </Box>
      {dismissible ? (
        <DismissibleButtonBox
          role={'button'}
          onClick={() => handleDismissal(dismissHandler, dismissalPending)}
          paddingRight={'xs'}
          paddingTop={'sm'}
        >
          <FeatherXIcon color={'neutral.080'} />
        </DismissibleButtonBox>
      ) : null}
    </DismissibleInfoPanel>
  );
};

import { gql } from '@apollo/client';
import { Box, InfoPanel, Text } from '@spaceship-fspl/components';
import { MoneyTransactionType } from '@spaceship-fspl/graphql';
import { MoneyTransactionDetails_MoneyTransactionDetailsFragment as MoneyTransactionDetails_MoneyTransactionDetailsFragmentType } from '@spaceship-fspl/graphql/src/__generated__/MoneyTransactionDetails_MoneyTransactionDetailsFragment';
import {
  MoneyTransactionList_MoneyTransactionListConnectionFragment as MoneyTransactionList_MoneyTransactionListConnectionFragmentType,
  type MoneyTransactionList_MoneyTransactionListConnectionFragment_edges_node,
} from '@spaceship-fspl/graphql/src/__generated__/MoneyTransactionList_MoneyTransactionListConnectionFragment';
import { FeatherInfoIcon } from '@spaceship-fspl/icons-web';
import {
  borderWidthTop,
  color,
  getColor,
  paddingX,
  paddingY,
  transition,
} from '@spaceship-fspl/styles';
import { RouterLink } from 'components/router-link';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  MoneyTransactionDetails_MoneyTransactionDetailsFragment,
  MoneyTransactionItem,
} from './money-transaction-item';

export const MoneyTransactionList_MoneyTransactionListConnectionFragment = gql`
  fragment MoneyTransactionList_MoneyTransactionListConnectionFragment on MoneyTransactionsConnection {
    edges {
      node {
        id
        ...MoneyTransactionDetails_MoneyTransactionDetailsFragment
      }
    }
    pageInfo {
      total
    }
  }
  ${MoneyTransactionDetails_MoneyTransactionDetailsFragment}
`;

export interface MoneyTransactionListProps {
  isLoading?: boolean;
  connection?: MoneyTransactionList_MoneyTransactionListConnectionFragmentType | null;
  isFetchingMore?: boolean;
}

export const MoneyTransactionList: React.FC<MoneyTransactionListProps> = ({
  connection,
  isFetchingMore,
  isLoading,
}) => {
  const total = connection?.pageInfo?.total ?? 0;
  const isListEmpty = total === 0;

  if (isListEmpty && isLoading) {
    return 'Loading...';
  }

  if (isListEmpty && !isLoading && !isFetchingMore) {
    return (
      <Box padding="lg">
        <Text variant={2} isBold={true} align="center">
          No transactions to display at this time.
        </Text>
      </Box>
    );
  }

  const isTransactionItemExpandable = (
    node: MoneyTransactionList_MoneyTransactionListConnectionFragment_edges_node | null,
  ): boolean => {
    switch (node?.type) {
      case MoneyTransactionType.MONEY_IN:
      case MoneyTransactionType.MONEY_OUT: {
        return true;
      }

      case MoneyTransactionType.BOOST: {
        return true;
      }

      case MoneyTransactionType.PURCHASE: {
        const productTransaction = node?.productTransaction;

        switch (productTransaction?.__typename) {
          case 'Application': {
            return true;
          }
        }

        return false;
      }

      case MoneyTransactionType.SALE: {
        const productTransaction = node?.productTransaction;

        switch (productTransaction?.__typename) {
          case 'Redemption': {
            return true;
          }
        }

        return false;
      }

      default: {
        return false;
      }
    }
  };

  return (
    <StyledTransactionListContainer
      data-testid={TestId.MONEY_TRANSACTION_LIST}
      isExpandableList={true}
    >
      <Box marginTop="xs" paddingX="sm">
        <InfoPanel
          hidePanelFill
          testId={TestId.MONEY_PENDING_ORDERS}
          color="neutral.100"
          icon={FeatherInfoIcon}
          backgroundColor="indigo.010"
          boxShadow="none"
          textVariant={4}
        >
          Any pending Spaceship Voyager or US Investing sell orders will show
          here once completed.
        </InfoPanel>
      </Box>

      {connection?.edges.map(({ node }) => (
        <MoneyTransactionItem
          moneyTransaction={
            node as MoneyTransactionDetails_MoneyTransactionDetailsFragmentType
          }
          isExpandable={isTransactionItemExpandable(node)}
          key={node?.id}
        />
      ))}
    </StyledTransactionListContainer>
  );
};

const dividerStyle = css`
  :not(:first-child) {
    ${borderWidthTop('sm')};
    border-top-style: solid;
    border-top-color: ${getColor('neutral.030')};
  }
`;

const StyledContentOnlyContainer = styled.div`
  ${dividerStyle}
  ${paddingY('sm')}
  border-right: green 3px solid;
`;

const StyledTransactionItemRouterLink = styled(RouterLink)`
  ${dividerStyle}
  ${paddingY('sm')}
  display: flex;
  align-items: center;

  * {
    ${transition}
  }

  :hover * {
    ${color('indigo.070')}
  }
`;

const StyledTransactionListContainer = styled.div<{
  isExpandableList: boolean;
}>`
  ${StyledContentOnlyContainer},
  ${StyledTransactionItemRouterLink} {
    ${paddingX({ xs: 'md', md: 'lg' })}
    ${paddingY('md')}
  }
`;
